import React from 'react';
import { Row, Col, Table, Space } from 'antd';
import { Edit, Delete } from '../utils';

const columns = [
  {
    title: 'First Aid Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: () => (
      <Space size="middle" align="horizontal">
        <div>
          <Edit />
        </div>
        <div>
          <Delete onClick={() => alert('Delete')} />
        </div>
      </Space>
    ),
  },
];

const FirstAidTypeTable = ({ types }) => {
  return (
    <Row>
      <Col span={5} />
      <Col span={14}>
        {types && (
          <Table columns={columns} dataSource={types} pagination={false} />
        )}
      </Col>
      <Col span={5} />
    </Row>
  );
};

export { FirstAidTypeTable };
