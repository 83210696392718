const expires = [
  {
    title: 'Active',
    value: 'active',
    key: 'active',
  },
  {
    title: 'Expired',
    value: 'expired',
    key: 'expired',
  },
  {
    title: 'Expiring Soon',
    value: 'expiring soon',
    key: 'expiring_soon',
  },
];

export { expires };
