  import dayjs from 'dayjs'
  const validateNewGuard = (guard) => {
    if (!validateGuardDetails(guard)) {
      return false
    }

    if (!validateLicenseDetails(guard)) {
      return false
    }

    if (!validateFirstAid(guard)) {
      return false
    }

    if (!validatePCR(guard)) {
      return false
    }

    return true
}

const validateGuardDetails = ({ first_name = undefined, last_name = undefined, dob = undefined }) => {
  if (first_name === undefined || last_name === undefined) {
    return false
  }

  if (first_name === null || last_name === null) {
    return false
  }

  if (!dob || !dayjs(dob).isValid()) {
    return false
  }

  return true
}

const validateLicenseDetails = ({ license_number = undefined, license_issued = undefined, license_expiry = undefined }) => {
  if (!license_number) {
    return false
  }

  if (!license_issued || !dayjs(license_issued).isValid()) {
    return false
  }

  if (!license_expiry || !dayjs(license_expiry).isValid()) {
    return false
  }

  return true
}

const validateFirstAid = (guard) => {
 if (guard.hasOwnProperty("first_aid")) {
  const {
    issuer,
    certificate_number,
    certificate_issued,
    certificate_expiry,
  } = guard.first_aid

  if (!issuer || !certificate_number) {
    process.env.REACT_APP_ENV == "dev" && console.log("validateFirstAid: issue or certificate number are null")
    return false
  }

  if (!certificate_issued || !dayjs(certificate_issued).isValid()) {
    process.env.REACT_APP_ENV == "dev" && console.log("validateFirstAid: certificate_issued is null or is not a valid date")
    return false
  }

  if (!certificate_expiry || !dayjs(certificate_expiry).isValid()) {
    process.env.REACT_APP_ENV == "dev" && console.log("validateFirstAid: certificate_expiry is null or is not a valid date")
    return false
  }
 }

  return true
}

const validatePCR = (guard) => {
 if (guard.hasOwnProperty("pcr")) {
  const {
    issuer,
    certificate_number,
    certificate_expiry,
    certificate_issued,
  } = guard.pcr

  if (!issuer || !certificate_number) {
    process.env.REACT_APP_ENV == "dev" && console.log("validatePCR: issue or certificate number are null")
    return false
  }

  if (!certificate_issued || !dayjs(certificate_issued).isValid()) {
    process.env.REACT_APP_ENV == "dev" && console.log("validatePCR: certificate_issued is null or is not a valid date")
    return false
  }

  if (!certificate_expiry || !dayjs(certificate_expiry).isValid()) {
    process.env.REACT_APP_ENV == "dev" && console.log("validatePCR: certificate_expiry is null or is not a valid date")
    return false
  }
 }

  return true
}

export { validateNewGuard }