import React from 'react';
import { Row, Col, Input, Modal, Space, DatePicker } from 'antd';
import '../../styles/theme.css';
// {
//     first_name: "Bob",
//     last_name: "Citizen",
//     dob: "01/10/1992",
//     statement_number: "01847",
//     date_issued: "01/08/2001",
//     expiry_date: "01/08/2028",
//     status: ""
// },

const inputs = [
  {
    key: 'first_name',
    label: 'First Name',
    type: 'text',
  },
  {
    key: 'last_name',
    label: 'Last Name',
    type: 'text',
  },
  {
    key: 'dob',
    label: 'Date Of Birth',
    type: 'date',
  },
  {
    key: 'statement_number',
    label: 'Statement Number',
    type: 'text',
  },
  {
    key: 'date_issued',
    label: 'Date Issued',
    type: 'date',
  },
  {
    key: 'expiry_date',
    label: 'Expiry Date',
    type: 'date',
  },
];

function NewAiderModal({ modal, closeModal }) {
  return (
    <Modal title="New First Aider" open={modal} onCancel={closeModal}>
      <Space direction="vertical" style={{ width: '100%' }}>
        {inputs.map((i, k) => (
          <Row style={{ paddingBottom: '3%' }} key={i.key}>
            <Col md={22} lg={22} sm={24}>
              <div className="label">
                <h3>{i.label}</h3>
                {i.type === 'text' && (
                  <Input
                    key={i.key}
                    placeholder={i.label}
                    style={{ width: '100%' }}
                  />
                )}
                {i.type === 'date' && <DatePicker key={i.key} />}
              </div>
            </Col>
          </Row>
        ))}
      </Space>
    </Modal>
  );
}

export { NewAiderModal };
