const useFilter = (applyFilter, filter) => {
  const handleTypesFilter = (_type) => {
    if (filter?.types && filter.types.includes(_type)) {
      applyFilter({ types: filter.types.filter((t) => t !== _type) });
    } else {
      applyFilter({
        types: filter?.types ? [...filter.types, _type] : [_type],
      });
    }
  };

  const handleStatusFilter = (status) => {
    if (filter?.status && filter.status.includes(status)) {
      applyFilter({ status: filter.status.filter((s) => s !== status) });
    } else {
      applyFilter({
        status: filter?.status ? [...filter.status, status] : [status],
      });
    }
  };

  const filterByFirstAidType = (aiders, firstAidTypeList) => {
    const result = aiders.filter((aider) =>
      aider.types?.some((_type) => firstAidTypeList.includes(_type)),
    );

    return result;
  };

  return { handleTypesFilter, handleStatusFilter, filterByFirstAidType };
};

export { useFilter };
