import { Space, Tag, Checkbox } from 'antd';
import {
  Edit,
  Delete,
  getTag,
  getFirstAidTag,
} from '../../components/utils';

const useTable = () => {
  const getColumns = ({ deleteGuard }) => {
    const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dob',
      key: 'dob',
    },
    {
      title: (
        <div>
          Security License
          <br />
          Number
        </div>
      ),
      dataIndex: 'license_number',
      key: 'license_number',
    },
    {
      title: (
        <div>
          Security License
          <br />
          Issued
        </div>
      ),
      dataIndex: 'license_issued',
      key: 'license_issued',
    },
    {
      title: (
        <div>
          Security License
          <br />
          Expiry
        </div>
      ),
      dataIndex: 'expiry_date',
      key: 'expiry_date',
    },
    {
      title: (
        <div>
          Time Left
          <br />
          Till Expiry
        </div>
      ),
      dataIndex: 'expiry_time',
      key: 'expiry_time',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        <Tag color={getTag(text)} style={{ textTransform: 'capitalize' }}>
          {text}
        </Tag>
      ),
    },
    {
      title: 'First Aid',
      dataIndex: 'first_aid',
      key: 'first_aid',
      render: (text) => getFirstAidTag(text),
    },
    {
      title: 'PCR',
      dataIndex: 'pcr',
      key: 'pcr',
      render: (text) => getFirstAidTag(text),
    },
    {
      title: 'Action',
      key: 'action',
      render: (guard) => (
        <Space size="middle" align="horizontal">
          <div>
            <Checkbox
              onChange={(e) => e.target.checked ? addMultiSelect(guard) : removeMultiSelect(guard)}
            />
          </div>
          <div>
            <Edit />
          </div>
          <div>
            <Delete onClick={async () => await deleteGuard(guard)} />
          </div>
        </Space>
      ),
    },
    ];

    return columns
  }

  return { getColumns }
}

export { useTable }