import React from 'react';
import { Row, Col, Table, Tag, Pagination } from 'antd';
import '../../styles/theme.css';
import { useTable, useGuards } from '../../hooks/guards' 

const GuardsTable = ({ guards, addMultiSelect, removeMultiSelect, deleteGuard}) => {
  const { getColumns } = useTable()
  const { getGuards } = useGuards()
  const getExpandable = () => {
    return {
      expandedRowRender: (record) => (
        <div>
          <Row
            style={{
              paddingLeft: 'none',
              backgroundImage: 'linear-gradient(176deg, #3d9dea, #4a4eee)',
            }}
          >
            <Col span={4}>
              <h3>Date Of Birth</h3>
            </Col>
            <Col span={4}>
              <h3>Activities</h3>
            </Col>
          </Row>
          <Row>
            <Col span={4}>{record?.dob}</Col>
            <Col span={4}>
              {
                !record.hasOwnProperty('activities') ||
                record.activities.length === 0 ? (
                  <Tag color="gold">No Activities Selected</Tag>
                ) : (
                  record.activities.join(', ')
                )
              }
            </Col>
          </Row>
        </div>
      ),
      rowExpandable: (record) => true,
    };
  };

  
  
  return (
    <>
      {guards && (
        <>
          <Row>
            <Col md={1} lg={1} sm={0} />
            <Col md={22} lg={22} sm={24}>
              <Table
                columns={getColumns({ deleteGuard })}
                dataSource={guards && getGuards(guards)}
                expandable={getExpandable()}
                pagination={{ position: [ 'bottomCenter'] }}
              />
            </Col>
            <Col md={1} lg={1} sm={0} />
          </Row>
        </>
      )}

      {!guards && (
        <Row>
          <Col md={22} lg={22} sm={24}>
            <h2>No Guards Found</h2>
          </Col>
        </Row>
      )}
    </>
  );
};

export { GuardsTable };
