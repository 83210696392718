import React from 'react';
import { Row, Col, Alert, Button } from 'antd';

const AlertMessage = (error) => {
  const getAlert = () => ({
    message: error.code,
    description: `${error.message} speak to Azy`,
    type: 'error',
    action: (
      <Button onClick={() => console.error(error)} size="small" danger>
        Detail
      </Button>
    ),
  });
  return (
    <Row>
      <Col md={4} />
      <Col md={16} lg={16} sm={24} style={{ textAlign: 'center' }}>
        <Alert {...getAlert()} />
      </Col>
      <Col md={4} />
    </Row>
  );
};

export { AlertMessage };
