import '../../styles/theme.css';
import styled from 'styled-components';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';

const Edit = styled(EditOutlined)`
  background-color: #1677ff;
  color: white;
  font-size: 1.4em;
  padding: 11% 11% 11% 11%;
  border-radius: 25%;
  z-index: 999;
  float: left;
  &:hover {
    cursor: pointer;
  }
`;

const Delete = styled(DeleteOutlined)`
  background-color: #ff4d4f;
  color: white;
  font-size: 1.4em;
  padding: 11% 11% 11% 11%;
  border-radius: 25%;
  z-index: 999;
  &:hover {
    cursor: pointer;
  }
`;

export {Edit, Delete};
