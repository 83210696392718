import React, { useEffect, useState } from 'react';
import '../../styles/theme.css';
import { NewGuardModal } from './NewGuardModal';
import { GuardsTable } from './GuardsTable';
import { AlertMessage, Heading } from '../utils';
import { Row } from 'antd';
import { GuardsFilter } from './GuardsFilter';
import { GuardsToolbar } from './GuardsToolbar';
import { useFilter, useGuards } from '../../hooks/guards';

function Guards() {
  const [guards, setGuards] = useState();
  const [filterTypes, setFilterTypes] = useState({});
  const [filteredData, setFilteredData] = useState('');
  const [modal, setModal] = useState(false);
  const [error, setError] = useState({ display: false, code: '', message: '' });
  const { listGuards, handleDeleteGuard } = useGuards()
  const { handleFilter, filterByActivity, filtersByExpiryStatus, filterByFirstAid } = useFilter();
  

  useEffect(() => {
    (async () => {
      if (!guards) {
      const result = await listGuards()
      console.log(result)
      if (result?.error) {
        console.log("error")
          setError({
            ...error,
            display: true,
            code: result.code,
            message: result.message,
            showIcon: true 
          })

          return;
      }
      setGuards(result?.data);
      setFilteredData(result?.data);
    }})();
  }, []);

  useEffect(() => {
    if (!guards) {
      return;
    }

    if (!filterTypes) {
      setFilteredData(guards);
      return;
    }

    let _guards = guards;

    if (filterTypes?.activities) {
      _guards = filterByActivity(_guards, filterTypes.activities);
    }

    if (filterTypes?.firstAid) {
      _guards = filterByFirstAid(_guards);
    }

    if (filterTypes?.status && filterTypes.status.length > 0) {
      _guards = filtersByExpiryStatus(_guards, filterTypes.status);
    }

    setFilteredData(_guards);
  }, [filterTypes]);

  

  
  return (
    <>
      <Heading text="guards" />
      <Row>
        {filterTypes && JSON.stringify(filterTypes)}
      </Row>
      <Row
        style={{
          paddingTop: '1%',
          paddingBottom: '2%',
        }}
      >
        {guards && (
          <GuardsFilter
            applyFilter={(filter) => { 
              // let newFilterTypes = handleFilter(filter, filterTypes)
              setFilterTypes(prevFilterTypes => ({...prevFilterTypes, ...handleFilter(filter, prevFilterTypes)}))
            }}
            clearFilter={() => setFilterTypes({})}
            filter={filterTypes}
          />
        )}
      </Row>
      {filteredData && (
        <GuardsTable
          guards={filteredData}
          addMultiSelect={(guard) => addMultiSelect(guard)}
          removeMultiSelect={(guard) => removeMultiSelect(guard)}
          deleteGuard={async (guard) => await handleDeleteGuard(guard, guards )}
        />
      )}
      <GuardsToolbar showModal={() => setModal(true)} />
      {error.display && <AlertMessage {...error} />}
      {modal && <NewGuardModal modal closeModal={() => setModal(false)} appendGuard={(guard) => setGuards([...guards, guard])}/>}
    </>
  );
}

export { Guards };
