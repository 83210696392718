import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Nav } from './Nav';
import { BrowserRouter } from 'react-router-dom';

async function enableMocking() {
  if (process.env.REACT_APP_ENV !== 'dev') {
    return;
  }

  const { worker } = await import('./mocks/');

  return worker.start();
}

/* eslint-disable */
enableMocking().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Nav />
        <App />
      </BrowserRouter>
    </React.StrictMode>,
  );
});

/* eslint-enable */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
