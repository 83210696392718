import React from 'react';
import { FloatButton } from 'antd';
import '../../styles/theme.css';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const GuardsToolbar = ({ showModal, multiSelect, showDeleteModal }) => {
  return (
    <>
      <FloatButton
        icon={<PlusOutlined />}
        type="primary"
        style={{
          right: 94,
        }}
        onClick={() => showModal()}
      />
      {multiSelect && multiSelect.length > 0 && (
        <FloatButton
          icon={<DeleteOutlined />}
          type="danger"
          style={{
            right: 144,
          }}
          onClick={() => showDeleteModal(true)}
        />
      )}
    </>
  );
};

export { GuardsToolbar };
