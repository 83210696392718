import React, { useEffect, useState } from 'react';
import '../../styles/theme.css';
import { Heading, AlertMessage } from '../utils';
import { FirstAidModal } from './FirstAidModal';
import axios from 'axios';
import { API_ENDPOINT } from '../../config';
import { FirstAidTypeToolbars } from './FirstAidTypeToolbars';
import { FirstAidTypeTable } from './FirstAidTypeTable';

function FirstAidTypes() {
  const [modal, setModal] = useState(false);
  const [types, setTypes] = useState('');
  const [error, setError] = useState({ display: false, code: '', message: '' });

  useEffect(() => {
    (async () => {
      /* eslint-disable */
      axios
        .get(`${API_ENDPOINT}/firstaidtypes`)
        .then((result) => setTypes(result.data))
        .catch((error) =>
          setError({
            ...error,
            display: true,
            code: error.code,
            message: error.message,
          }),
        );
      /* eslint-enable */
    })();
  }, []);

  return (
    <>
      <Heading text="First Aid Types" />
      {types && <FirstAidTypeToolbars showModal={() => setModal(true)} />}
      {types && <FirstAidTypeTable types={types} />}
      {error.display && <AlertMessage {...error} />}
      {modal && (
        <FirstAidModal modal={modal} closeModal={() => setModal(false)} />
      )}
    </>
  );
}

export { FirstAidTypes };
