import { calculateExpiryTime, getExpiryStatus } from '../../components/utils';

const removeEmptyObjects = (obj) => {
  return obj.filter(o => Object.values(o).length > 0)
}


// dummy obj
let filterObjectExample = {
  activities: [],
  status: [],
  firstAid: true
}

const handleBooleanFilter = (key, value, filterTypes) => {
  console.log("handleBooleanFilter: " + JSON.stringify(filterTypes))
  if (value === false) {
    delete filterTypes[key]
  }
  else {
    Object.assign(filterTypes, { [key]:  value })
  }
  console.log(filterTypes)
  return filterTypes
}

const handleArrayFilter = (key, value, filterTypes) => {
  // If does exist
  // _filterTypes = filterTypes.filter(f => Object.keys(f)[0] != key && Object.values(f)[0] !== value)
  
  // If does not exist
  // _filterTypes[key].push(value)
}

const useFilter = () => {
  const handleFilter = (filter, filterTypes) => {
    const key = Object.keys(filter)[0]
    const value = Object.values(filter)[0]
    
    // console.log(filter)
    if (typeof value === 'boolean') {
      filterTypes = handleBooleanFilter(key, value, filterTypes)
    }
    else if (Array.isArray(value)) {
      filterTypes = handleArrayFilter(key, value, filterTypes)
    } 

    console.log("handleFilter: " + JSON.stringify(filterTypes))
    return filterTypes
  }


  const filterByActivity = (guards, activityList) => {
    const result = guards.filter((guard) =>
      guard.activities?.some((activity) => activityList.includes(activity)),
    );

    return result;
  };

  const filtersByExpiryStatus = (guardsOrAiders, statusList) => {
    const result = guardsOrAiders.filter((guardOrAider) => {
      const expiryTime = calculateExpiryTime(guardOrAider.expiry_date);
      const status = getExpiryStatus(expiryTime);
      if (statusList.includes(status)) return guardsOrAiders;
    });

    return result;
  };

  const filterByFirstAid = (guards) => {
    return guards.filter((guard) => guard.first_aid == true);
  };

  return { filterByActivity, filtersByExpiryStatus, filterByFirstAid, handleFilter };
};

export { useFilter };
