import React, { useState } from 'react';
import { Input, Modal } from 'antd';
// import axios from 'axios';

function NewActivityModal({ modal, closeModal }) {
  const [activity, setActivity] = useState('');
  const handleOk = () => {
    return true;
  };
  const handleCancel = () => closeModal();
  return (
    <Modal
      title="Add New Activity"
      centered={true}
      open={modal}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Input
        placeholder="New Activity"
        value={activity}
        onChange={(e) => setActivity(e.target.value)}
      />
    </Modal>
  );
}

export { NewActivityModal };
