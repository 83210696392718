import React from 'react';
import { Tag } from 'antd';

const getTag = (text) => {
  switch (text) {
    case 'expired':
      return 'red';
    case 'expiring soon':
      return 'gold';
    case 'active':
      return 'green';
    default:
      return null;
  }
};

const getFirstAidTag = (text) =>
  !text ? <Tag color="blue">No</Tag> : <Tag color="green">Yes</Tag>;

export { getTag, getFirstAidTag };
