import React, { useState } from 'react';
import { Input, Modal } from 'antd';
// import axios from 'axios';

function FirstAidModal({ modal, closeModal }) {
  const [firstAid, setFirstAid] = useState('');
  const handleOk = () => {
    return true;
  };
  const handleCancel = () => closeModal();
  return (
    <Modal
      title="First Aid Type"
      centered={true}
      open={modal}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Input
        placeholder="New First Aid Type"
        value={firstAid}
        onChange={(e) => setFirstAid(e.target.value)}
      />
    </Modal>
  );
}

export { FirstAidModal };
