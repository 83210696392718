import React from 'react';
import { Row, Col } from 'antd';

const Heading = ({ text }) => {
  return (
    <Row style={{ paddingTop: '3%' }}>
      <Col md={4} lg={4} sm={0} />
      <Col md={16} lg={16} sm={24} style={{ textAlign: 'center' }}>
        <h1 style={{ fontFamily: 'RobotoBold', color: '#323232' }}>
          {text.toUpperCase()}
        </h1>
      </Col>
      <Col md={4} lg={4} sm={0} />
    </Row>
  );
};

export { Heading };
