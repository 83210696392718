import axios from 'axios'
import { API_ENDPOINT } from '../../config'
import {
  calculateExpiryTime,
  getExpiryStatus,
} from '../../components/utils';

import { validateNewGuard } from './validateGuard'

const getExpiryTimeFormat = (expiryTime) => {
  if (!expiryTime.startsWith('-')) {
    return expiryTime;
  }
  return expiryTime.replace('-', '') + ' ago'
}

const useGuards = () => {
  const listGuards = async () => {
    console.log("listGuards")
    const { 
      data: { response } 
    } = await axios.get(`${API_ENDPOINT}/guards?list`, { headers: { "Access-Control-Allow-Origin": "http://localhost:3000" }} )
    return response || {}        
  }

  const deleteGuard = async (guard) => {
    console.log(`[INFO] Deleting Guard ${JSON.stringify(guard)}`);
    const {
      data: { response },
    } = await axios.delete(`${API_ENDPOINT}/guards`, guard);
    console.log(response)
    return response
  };

  const handleDeleteGuard = async (guard, guards) => {
    const response = await deleteGuard(guard)
    if (response === 200) {
      console.log('response 200')
      return guards.filter(g => g.id !== guard.id)
    }
  }

  const getGuards = (guards = []) => {
    return guards && guards.map((guard) => {
      if (guard?.expiry_date) {
        const expiryTime = calculateExpiryTime(guard.expiry_date);
        guard.expiry_time = getExpiryTimeFormat(expiryTime)
        guard.status = getExpiryStatus(expiryTime);
      }
      return guard || {};
    });
  };

  const addGuard = async (guard) => {
    if (!validateNewGuard(guard)) {
      const error = { error: 404, description: "Required fields <insert here> not provided when trying to add guard" } 
      console.error(error)
      return { error: 404, description: "Required fields <insert here> not provided when trying to add guard" };
    }
    const response = await axios.post(`${API_ENDPOINT}/guards`, guard)
    return response
  }

  return { listGuards, deleteGuard, getGuards, handleDeleteGuard, addGuard }
}

export { useGuards }