import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { API_ENDPOINT } from '../../config';
import dayjs from 'dayjs';
import axios from 'axios';
import { Tile } from './Tile';

const Home = () => {
  const [guards, setGuards] = useState('');

  useEffect(() => {
    if (!guards) {
      try {
        axios.get(`${API_ENDPOINT}/guards`, { headers: { "Access-Control-Allow-Origin": "http://localhost:3000" }}).then((result) => {
          const { data } = result;
          data.sort((a, b) => {
            const lastViewedA = dayjs(a.last_viewed, 'DD/MM/YYYY');
            const lastViewedB = dayjs(b.last_viewed, 'DD/MM/YYYY');
            return lastViewedB - lastViewedA;
          });
          setGuards(data);
        });
      } catch (err) {
        console.error(`[ERROR] ${err}`);
      }
    }
  }, []);

  {
    /* eslint-disable */
  }
  return (
    <>
      <Row>
        <Col span={4} />
        <Col
          span={8}
          style={{ textAlign: 'center', paddingTop: '5%', paddingBottom: '3%' }}
        >
          <h1>Most Recent</h1>
        </Col>
        <Col span={8} />
      </Row>
      <Row style={{ height: '40vh', marginBottom: '10%' }} gutter={20}>
        <Col span={2} style={{ height: '20%', paddingBottom: '20%' }} />
        {guards &&
          [
            guards[0] || null,
            guards[1] || null,
            guards[2] || null,
            guards[3] || null,
          ].map((guard) =>
            guard ? (
              <Col
                span={5}
                key={`${guard.key}_home_col`}
                style={{ height: '100%', marginBottom: '15%' }}
              >
                <Tile {...guard} key={guard?.id} />
              </Col>
            ) : null,
          )}
      </Row>
      <Row style={{ height: '40vh', marginBottom: '10%' }} gutter={20}>
        <Col span={2} style={{ height: '20%', paddingBottom: '20%' }} />
        {guards &&
          [
            guards[4] || null,
            guards[5] || null,
            guards[6] || null,
            guards[7] || null,
          ].map((guard) =>
            guard ? (
              <Col
                span={5}
                key={`${guard.key}_home_col`}
                style={{ height: '100%', marginBottom: '15%' }}
              >
                <Tile {...guard} key={guard?.id} />
              </Col>
            ) : null,
          )}
      </Row>
      <Row style={{ height: '40vh', marginBottom: '10%' }} gutter={20}>
        <Col span={2} style={{ height: '20%', paddingBottom: '20%' }} />
        {guards &&
          [
            guards[8] || null,
            guards[9] || null,
            guards[10] || null,
            guards[11] || null,
          ].map((guard) =>
            guard ? (
              <Col
                span={5}
                key={`${guard.key}_home_col`}
                style={{ height: '100%', marginBottom: '15%' }}
              >
                <Tile {...guard} key={guard?.id} />
              </Col>
            ) : null,
          )}
      </Row>
    </>
  );
};

export { Home };
