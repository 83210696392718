import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Space,
  Input,
  Modal,
  DatePicker,
  Select,
  Button,
} from 'antd';
import axios from 'axios';
import { API_ENDPOINT } from '../../config';
import '../../styles/theme.css';
import { getEighteenYearsAgo } from '../utils';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
import relativeTime from 'dayjs/plugin/relativeTime.js';
import { useGuards, validateNewGuard } from '../../hooks/guards'
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

const FirstAidType = ({ handleGuard }) => {
  const [types, setTypes] = useState('');

  useEffect(() => {
    if (!types) {
      /* eslint-disable */
      axios.get(`${API_ENDPOINT}/firstaidtypes`).then((result) => {
        setTypes(result.data);
      });
      /* eslint-enable */
    }
  });
  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <h3>First Aid Type</h3>
      <Select
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        placeholder="Please select first aid type"
        onChange={handleGuard}
        options={
          types &&
          types.map((t) => {
            return { key: t.id, value: t.type };
          })
        }
      />
    </Space>
  );
};

const Activities = ({ handleGuard, activities }) => {
  const [activitiesList, setActivitiesList] = useState();

  useEffect(() => {
    if (!activities) {
      /* eslint-disable */
      axios.get(`${API_ENDPOINT}/activities`).then((result) => {
        console.log(result);
        setActivitiesList(result.data);
      });
      /* eslint-enable */
    }
  }, []);
  return (
    <>
      <Row>
        <h3>Activities</h3>
      </Row>
      <Row>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Please select activities"
          onChange={(value) => handleGuard(value)}
          options={
            activitiesList &&
            activitiesList.map((a) => {
              return {
                value: a.activity,
                key: a.id,
              };
            })
          }
        />
      </Row>
    </>
  );
};

const GuardDetails = ({ handleGuard, first_name, last_name, dob }) => {
  return (
    <>
      <Row>
        <h3>Guard Details</h3>
      </Row>
      <Row style={{ paddingBottom: '3%' }} gutter={{ md: 2, lg: 2, sm: 2 }}>
        <Col md={6} lg={6} sm={6}>
          First Name
          <Input
            onChange={(e) => handleGuard({ first_name: e.target.value })}
            value={first_name || null}
          />
        </Col>
        <Col md={6} lg={6} sm={6}>
          Last Name
          <Input
            onChange={(e) => handleGuard({ last_name: e.target.value })}
            value={last_name || null}
          />
        </Col>
        <Col md={6} lg={6} sm={6}>
          Date of Birth
          <br />
          <DatePicker
            onChange={(d) => handleGuard({ dob: d })}
            format={'DD-MM-YYYY'}
            value={dob || null}
            maxDate={dayjs(getEighteenYearsAgo(), 'DD-MM-YYYY')}
          />
        </Col>
      </Row>
    </>
  );
};

const LicenseDetails = ({
  handleGuard,
  license_number,
  license_issued,
  license_expiry,
}) => {
  return (
    <>
      <Row>
        <h3>License Details</h3>
      </Row>
      <Row style={{ paddingBottom: '3%' }} gutter={{ md: 2, lg: 2, sm: 2 }}>
        <Col md={6} lg={6} sm={6}>
          License Number
          <Input
            onChange={(e) => handleGuard({ license_number: e.target.value })}
            value={license_number || null}
          />
        </Col>
        <Col md={6} lg={6} sm={6}>
          License Issued
          <br />
          <DatePicker
            onChange={(license_issued) =>
              handleGuard({ license_issued, license_expiry: null })
            }
            format={'DD-MM-YYYY'}
            maxDate={dayjs('DD-MM-YYYY')}
            value={license_issued || null}
          />
        </Col>
        <Col md={6} lg={6} sm={6}>
          License Expiry
          <br />
          <DatePicker
            onChange={(license_expiry) => handleGuard({ license_expiry })}
            format={'DD-MM-YYYY'}
            minDate={
              /* eslint-disable */
              license_issued
                ? dayjs(new Date(license_issued)).add(1, 'day')
                : dayjs().tz('Australia/Melbourne')
              /* eslint-enable */
            }
            value={license_expiry || null}
          />
        </Col>
      </Row>
    </>
  );
};

const FirstAid = ({
  handleGuard,
  hideFirstAid,
  issuer,
  certificate_number,
  certificate_issued,
  certificate_expiry,
}) => {
  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Row>
        <Col span={20}>
          <h3>First Aid</h3>
        </Col>
        <Col span={4}>
          <Button type="primary" danger onClick={hideFirstAid}>
            Remove First Aid
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={6} sm={6}>
          Issuing Company
          <br />
          <Input
            onChange={(e) => handleGuard({ issuer: e.target.value })}
            value={issuer || null}
          />
        </Col>
        <Col md={1} lg={1} sm={1} />
        <Col md={6} lg={6} sm={6}>
          Certificate Number
          <br />
          <Input
            onChange={(e) =>
              handleGuard({ certificate_number: e.target.value })
            }
            value={certificate_number || null}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={6} sm={6}>
          Certificate Issued
          <br />
          <DatePicker
            onChange={(certificate_issued) =>
              handleGuard({ certificate_issued })
            }
            format={'DD-MM-YYYY'}
            maxDate={dayjs(new Date()).tz('Australia/Melbourne')}
            value={certificate_issued || null}
          />
        </Col>
        <Col md={1} lg={1} sm={1} />
        <Col md={6} lg={6} sm={6}>
          Certificate Expiry
          <br />
          <DatePicker
            onChange={(certificate_expiry) =>
              handleGuard({ certificate_expiry })
            }
            format={'DD-MM-YYYY'}
            minDate={
              /* eslint-disable */
              certificate_issued
                ? dayjs(new Date(certificate_issued)).add('1', 'day')
                : dayjs()
              /* eslint-enable */
            }
            value={certificate_expiry || null}
          />
        </Col>
      </Row>
    </Space>
  );
};

const PCRDetails = ({
  handleGuard,
  issuer,
  certificate_number,
  certificate_issued,
  certificate_expiry,
  hidePCR,
}) => {
  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Row>
        <Col span={20}>
          <h3>PCR</h3>
        </Col>
        <Col span={4}>
          <Button type="primary" danger onClick={hidePCR}>
            Remove PCR
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={6} sm={6}>
          Issuing Company
          <br />
          <Input
            onChange={(e) => handleGuard({ issuer: e.target.value })}
            value={issuer || null}
          />
        </Col>
        <Col md={1} lg={1} sm={1} />
        <Col md={6} lg={6} sm={6}>
          Certificate Number
          <br />
          <Input
            onChange={(e) =>
              handleGuard({ certificate_number: e.target.value })
            }
            value={certificate_number || null}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={6} sm={6}>
          Certificate Issued
          <br />
          <DatePicker
            onChange={(date) =>
              handleGuard({
                certificate_issued: dayjs(date, 'DD-MM-YYYY'),
              })
            }
            value={certificate_issued || null}
            format={'DD-MM-YYYY'}
            /* eslint-disable */
            maxDate={dayjs(new Date()).tz('Australia/Melbourne')}
            /* eslint-enable */
          />
        </Col>
        <Col md={1} lg={1} sm={1} />
        <Col md={6} lg={6} sm={6}>
          Certificate Expiry
          <br />
          <DatePicker
            onChange={(date) =>
              handleGuard({ certificate_expiry: dayjs(date, 'DD-MM-YYYY') })
            }
            value={certificate_expiry || null}
            format={'DD-MM-YYYY'}
            minDate={
              /* eslint-disable */
              certificate_issued
                ? dayjs(new Date(certificate_issued)).add('1', 'day')
                : dayjs()
              /* eslint-enable */
            }
          />
        </Col>
      </Row>
    </Space>
  );
};

const FirstAidExist = ({ handleExist, firstAidExist }) => {
  return (
    <div>
      <h3>Does this guard have first aid?</h3>
      <br />
      <Button onClick={handleExist}>Yes</Button>
      <Button type={!firstAidExist ? 'primary' : 'default'}>No</Button>
      <br />
    </div>
  );
};

const PCRExist = ({ handleExist, pcrExist }) => {
  return (
    <div>
      <h3>Does this guard have PCR?</h3>
      <br />
      <Button onClick={handleExist}>Yes</Button>
      <Button type={!pcrExist ? 'primary' : 'default'}>No</Button>
      <br />
    </div>
  );
};

const hrStyle = {
  backgroundColor: 'lightgrey',
  border: 'none',
  borderTop: '1px solid lightgrey',
};

function NewGuardModal({ modal, closeModal, appendGuard }) {
  const [guard, setGuard] = useState();
  const [firstAidExist, setFirstAidExist] = useState(undefined);
  const [valid, setValid] = useState(false)
  const [pcrExist, setPCRExist] = useState(undefined);
  const { addGuard } = useGuards()
  useEffect(() => {
    setValid(validateNewGuard(guard || {}))
  }, [guard])

  const handleNewGuard = async () => {
    const response = await addGuard(guard)
    if (response.status === 200) {
      console.log("We have received a response for New Guard")
      console.log(guard || "undefined guard")
      console.log(JSON.stringify(response))
      appendGuard(guard)
      closeModal()
    }
  }

  return (
    <Modal
      open={modal}
      title="Add New Guard"
      onCancel={() => closeModal()}
      width={'80%'}
      onOk={async () => await handleNewGuard(guard, closeModal, appendGuard)}
      okButtonProps={{ disabled: !valid }}
    >
    
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row>
          <hr />
          {valid ? "Valid True" : "No Valid"}<br />
          {typeof valid}<br />
          {valid && valid.toString()}<br />
          {guard && JSON.stringify(guard)}
        </Row>
        <GuardDetails
          {...guard}
          handleGuard={(value) => setGuard({ ...guard, ...value })}
        />
        <hr style={hrStyle} />
        <LicenseDetails
          {...guard}
          handleGuard={(value) => setGuard({ ...guard, ...value })}
        />
        <hr style={hrStyle} />
        <Activities
          {...guard}
          handleGuard={(value) => setGuard({ ...guard, activities: value })}
        />
        {firstAidExist && (
          <>
            <hr style={hrStyle} />
            <FirstAid
              {...guard?.first_aid}
              handleGuard={(value) =>
                setGuard({
                  ...guard,
                  first_aid: { ...guard?.first_aid, ...value },
                })
              }
              hideFirstAid={() => {
                setFirstAidExist(false);
                delete guard?.first_aid;
                setGuard(guard);
              }}
            />
            <hr style={hrStyle} />
            <FirstAidType
              {...guard?.first_aid}
              handleGuard={(value) =>
                setGuard({
                  ...guard,
                  first_aid: { ...guard?.first_aid, ...value },
                })
              }
            />
          </>
        )}
        {!firstAidExist && (
          <>
            <hr style={hrStyle} />
            <FirstAidExist
              handleExist={() => setFirstAidExist(!firstAidExist)}
              {...firstAidExist}
            />
          </>
        )}

        {pcrExist && (
          <>
            <hr style={hrStyle} />
            <PCRDetails
              {...guard?.pcr}
              handleGuard={(value) =>
                setGuard({ ...guard, pcr: { ...guard?.pcr, ...value } })
              }
              hidePCR={() => {
                setPCRExist(false);
                delete guard?.pcr;
                setGuard(guard);
              }}
            />
          </>
        )}
        {!pcrExist && (
          <>
            <hr style={hrStyle} />
            <PCRExist
              handleExist={() => setPCRExist(!pcrExist)}
              {...pcrExist}
            />
          </>
        )}
      </Space>
    </Modal>
  );
}

export { NewGuardModal };
