import { Routes, Route } from 'react-router-dom';
import { Guards } from './components/guards';
import { Aiders } from './components/aiders';
import { Activities } from './components/activities';
import { FirstAidTypes } from './components/first_aid_types';
import { Home } from './components/home';
import React from 'react';
import { API_ENDPOINT } from './config';
import { Col, Row, Alert } from 'antd';

function App() {
  return (
    <div className="App">
      {!API_ENDPOINT && (
        <Row>
          <Col span={8} />
          <Col span={8} style={{ paddingTop: '5%' }}>
            <Alert
              message="Error"
              description="No API ENDPOINT environment variable set!"
              type="error"
              showIcon
            />
          </Col>
          <Col span={8} />
        </Row>
      )}
      {API_ENDPOINT && (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/guards" element={<Guards />} />
          <Route path="/first_aid" element={<Aiders />} />
          <Route path="/activities/list" element={<Activities />} />
          <Route path="/firstaidtype/list" element={<FirstAidTypes />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
