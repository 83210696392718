import React, { useEffect, useState } from 'react';
import '../../styles/theme.css';
import { NewActivityModal } from './NewActivityModal';
import { AlertMessage, Heading } from '../utils';
import axios from 'axios';
import { API_ENDPOINT } from '../../config';
import { ActivitiesToolbar } from './ActivitiesToolbar';
import { ActivitiesTable } from './ActivitiesTable';

function Activities() {
  const [modal, setModal] = useState(false);
  const [activities, setActivities] = useState('');
  const [error, setError] = useState({ display: false, code: '', message: '' });

  useEffect(() => {
    (async () => {
      /* eslint-disable */
      axios
        .get(`${API_ENDPOINT}/activities`)
        .then((result) => setActivities(result.data))
        .catch((error) =>
          setError({
            ...error,
            display: true,
            code: error.code,
            message: error.message,
          }),
        );
      /* eslint-enable */
    })();
  }, []);

  return (
    <>
      <Heading text="Activites" />
      {activities && <ActivitiesToolbar showModal={() => setModal(true)} />}
      {activities && <ActivitiesTable activities={activities} />}
      {error.display && <AlertMessage {...error} />}
      {modal && (
        <NewActivityModal modal={modal} closeModal={() => setModal(false)} />
      )}
    </>
  );
}

export { Activities };
