import React from 'react';
import { Row, Col, Table, Space, Tag, Checkbox } from 'antd';
import '../../styles/theme.css';
import {
  Edit,
  Delete,
  calculateExpiryTime,
  getExpiryStatus,
  getTag,
} from '../utils';

const getExpandable = () => {
  return {
    expandedRowRender: (record) => (
      <div>
        <Row>
          <Col span={4}>
            <h3>Date Of Birth</h3>
          </Col>
          <Col span={4}>
            <h3>First Aid Types</h3>
          </Col>
        </Row>
        <Row>
          <Col span={4}>{record?.dob}</Col>
          <Col span={4}>
            {
              !record?.types ||
              /* eslint-disable */
              record.types.length === 0 ? (
                <Tag color="gold">No First Aid Types Selected</Tag>
              ) : (
                record.types.join(', ')
              )
              /* eslint-enable */
            }
          </Col>
        </Row>
      </div>
    ),
    rowExpandable: (record) => true,
  };
};

const AidersTable = ({ aiders, addMultiSelect, removeMultiSelect }) => {
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dob',
      key: 'dob',
    },
    {
      title: (
        <div>
          Statement
          <br />
          Number
        </div>
      ),
      dataIndex: 'statement_number',
      key: 'statement_number',
    },
    {
      title: (
        <div>
          Date
          <br />
          Issued
        </div>
      ),
      dataIndex: 'date_issued',
      key: 'date_issued',
    },
    {
      title: (
        <div>
          Expiry
          <br />
          Date
        </div>
      ),
      dataIndex: 'expiry_date',
      key: 'expiry_date',
    },
    {
      title: (
        <div>
          Time Left
          <br />
          Till Expiry
        </div>
      ),
      dataIndex: 'expiry_time',
      key: 'expiry_time',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        <Tag color={getTag(text)} style={{ textTransform: 'capitalize' }}>
          {text}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (aider) => (
        <Space size="middle" align="horizontal">
          <div>
            <Checkbox
              onChange={(e) => {
                /* eslint-disable*/
                e.target.checked
                  ? addMultiSelect(aider)
                  : removeMultiSelect(aider);
                /* eslint-enable*/
              }}
            />
          </div>
          <div>
            <Edit />
          </div>
          <div>
            <Delete onClick={() => alert('Delete')} />
          </div>
        </Space>
      ),
    },
  ];

  return (
    <Row>
      <Col md={1} lg={1} sm={0} />
      <Col md={22} lg={22} sm={24}>
        <Table
          columns={columns}
          dataSource={aiders.map((aider) => {
            const expiryTime = calculateExpiryTime(aider.expiry_date);
            /* eslint-disable */
            aider.expiry_time = expiryTime.startsWith('-')
              ? expiryTime.replace('-', '') + ' ago'
              : expiryTime;
            /* eslint-enable */
            aider.status = getExpiryStatus(expiryTime);
            return aider;
          })}
          pagination={{ position: ['bottomCenter'] }}
          expandable={getExpandable()}
        />
      </Col>
    </Row>
  );
};

export { AidersTable };
