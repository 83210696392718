import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/theme.css';
import { Menu, Tag } from 'antd';
import { Col, Row } from 'antd';
import logo from './assets/logo/logo.png';

const links = [
  {
    label: 'Home',
    key: '/',
  },
  {
    label: 'Guards',
    key: '/guards',
  },
  {
    label: 'Activities',
    key: '/activities/list',
  },
  {
    label: 'First Aid Types',
    key: '/firstaidtype/list',
  },
  {
    label: 'Help',
    key: '/help',
  },
];

const redirectTo = (navigate, link) => navigate(link.key);

function Nav() {
  const navigate = useNavigate();

  return (
    <Row id="nav">
      <Col
        span={2}
        style={{
          backgroundColor: '#333',
          paddingTop: '0.5%',
          paddingLeft: '1%',
        }}
      >
        <img src={logo} width="80%" height="80%" />
      </Col>
      <Col
        span={14}
        style={{
          width: '100%',
          backgroundColor: '#333',
          color: 'white',
          paddingBottom: '0%',
          fontFamily: 'Roboto',
        }}
      >
        <Menu
          onClick={(e) => redirectTo(navigate, e)}
          mode="horizontal"
          items={links}
          style={{
            width: '100%',
            backgroundColor: '#333',
            color: '#3366CC',
            paddingTop: '1%',
            paddingBottom: '1%',
            fontFamily: 'Roboto',
          }}
        />
      </Col>
      {process.env.REACT_APP_ENV === 'dev' && (
        <Col
          span={3}
          style={{
            width: '100%',
            backgroundColor: '#333',
            color: 'white',
            paddingBottom: '0%',
            fontFamily: 'Roboto',
          }}
        >
          <Tag color="green" style={{ marginTop: '10%' }}>
            DEV
          </Tag>
        </Col>
      )}
      <Col
        span={5}
        style={{
          width: '100%',
          backgroundColor: '#333',
          color: 'white',
          paddingTop: '1%',
          paddingBottom: '0%',
          fontFamily: 'Roboto',
        }}
      ></Col>
    </Row>
  );
}

export { Nav };
