import React, { useState } from 'react';
import { Row, Col, Table, Space } from 'antd';
import '../../styles/theme.css';
import { Edit, Delete } from '../utils';
// import axios from 'axios';
// import { API_ENDPOINT } from '../../config';

import { ActivitiesToolbar } from './ActivitiesToolbar';
import { NewActivityModal } from './NewActivityModal';

const columns = [
  {
    title: 'Activity',
    dataIndex: 'activity',
    key: 'activity',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: () => (
      <Space size="middle" align="horizontal">
        <div>
          <Edit />
        </div>
        <div>
          <Delete onClick={() => alert('Delete')} />
        </div>
      </Space>
    ),
  },
];

const ActivitiesTable = ({ activities }) => {
  const [modal, setModal] = useState(false);
  return (
    <Row>
      <Col span={5} />
      <Col span={14}>
        {activities && (
          <Table columns={columns} dataSource={activities} pagination={false} />
        )}
      </Col>
      <Col span={5} />
      <ActivitiesToolbar showModal={() => setModal(true)} />
      {modal && <NewActivityModal />}
    </Row>
  );
};

export { ActivitiesTable };
