import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import '../../styles/theme.css';
import { Heading, AlertMessage } from '../utils';
import { NewAiderModal } from './NewAiderModal';
import { AidersToolbar, AidersTable } from './';
import { API_ENDPOINT } from '../../config';
import axios from 'axios';
import { AiderFilter } from './AiderFilter';
import { useFilter } from '../../hooks/aiders';

function Aiders() {
  const [aiders, setAiders] = useState('');
  const [filter, setFilter] = useState('');
  const [data, setData] = useState('');
  const [modal, setModal] = useState(false);
  //   const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [multiSelect, setMultiSelect] = useState('');
  const [error, setError] = useState({ display: false, code: '', message: '' });
  const { filterByFirstAidType, filtersByExpiryStatus } = useFilter();

  //   const deleteModal = () => {
  //     return (
  //       <Modal
  //         title="Confirm Delete"
  //         open={deleteConfirmModal}
  //         // onOk={handleOk}
  //         onCancel={() => setDeleteConfirmModal(false)}
  //       >
  //         <p>Are you sure you want to delete selected?</p>
  //       </Modal>
  //     );
  //   };

  useEffect(() => {
    (async () => {
      /* eslint-disable */
      axios
        .get(`${API_ENDPOINT}/aiders`)
        .then((result) => {
          setAiders(result.data);
          setData(result.data);
        })
        .catch((error) =>
          setError({
            ...error,
            display: true,
            code: error.code,
            message: error.message,
            showIcon: true,
          }),
        );
      /* eslint-enable */
    })();
  }, []);

  useEffect(() => {
    if (!aiders) {
      return;
    }

    if (!filter) {
      setData(aiders);
      return;
    }

    let _aiders = aiders;

    if (filter?.types && filter.types.length > 0) {
      _aiders = filterByFirstAidType(_aiders, filter.types);
    }

    if (filter?.status && filter.status.length > 0) {
      _aiders = filtersByExpiryStatus(_aiders, filter.status);
    }

    setData(_aiders);
  }, [filter]);

  const handleFilter = (filterTypes) => {
    alert(JSON.stringify(filterTypes));
    const { types, status } = filterTypes;

    if (types) {
      setFilter({ ...filter, ...types });
    }

    if (status) {
      setFilter({ ...filter, filterTypes });
    }

    if (types && types.length == 0) {
      delete filter['types'];
    }

    if (status && status.length == 0) {
      delete filter['status'];
    }

    setFilter({ ...filter, ...filterTypes });
  };

  return (
    <>
      <Heading text="First Aiders" />
      {multiSelect && JSON.stringify(multiSelect)}
      {filter && JSON.stringify(filter)}
      <Row
        style={{
          paddingTop: '1%',
          paddingBottom: '2%',
        }}
      >
        {aiders && (
          <AiderFilter
            applyFilter={(filterTypes) => handleFilter(filterTypes)}
            filter={filter}
          />
        )}
      </Row>

      {aiders && (
        <AidersToolbar
          filter={filter}
          multiSelect={multiSelect}
          //   showModal={() => setModal(true)}
          //   showDeleteModal={() => setDeleteConfirmModal(true)}
        />
      )}
      {data && (
        <AidersTable
          aiders={data}
          addMultiSelect={(aider) => setMultiSelect([...multiSelect, aider])}
          removeMultiSelect={(aider) =>
            setMultiSelect(multiSelect.filter((_aider) => _aider !== aider))
          }
        />
      )}
      {error.display && <AlertMessage {...error} />}
      {modal && (
        <NewAiderModal modal={modal} closeModal={() => setModal(false)} />
      )}
      {/* {deleteConfirmModal && deleteModal()} */}
    </>
  );
}

export { Aiders };
