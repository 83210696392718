/* eslint-disable */
import '../../styles/theme.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
import relativeTime from 'dayjs/plugin/relativeTime.js';
import { dateOutput, getExpiryStatus, getTag } from '../utils';
import { AlertFilled } from '@ant-design/icons';
import { Tag, Row, Col } from 'antd';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

const Tile = ({
  first_name,
  last_name,
  last_viewed,
  expiry_date,
  activities,
  first_aid,
  id,
}) => {
  const TimeLeftAndFirstAid = ({ current, expiryDate, first_aid, id }) => {
    const timeLeft = dateOutput(current, expiryDate);
    return (
      <Row>
        <Col span={10}>
          <h3>Time Left</h3>
          <p>{timeLeft}</p>
          <div>
            <Tag
              color={getTag(getExpiryStatus(timeLeft))}
              style={{ textTransform: 'capitalize' }}
              key={id}
            >
              {getExpiryStatus(timeLeft)}
            </Tag>
          </div>
        </Col>
        <Col span={8} />
        <Col span={6}>
          {first_aid && (
            <div
              className="first_aid"
              style={{ border: '1px solid #333', float: 'right' }}
            >
              <AlertFilled
                style={{ color: 'white', fontSize: '30px' }}
                key={id}
              />
            </div>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <div className="tile">
      <div className="duration">
        <h3>Expiry Date</h3>
        <p>
          {dayjs(expiry_date, 'DD/MM/YYYY')
            .tz('Australia/Melbourne')
            .format('D MMMM YYYY')}
        </p>

        <h3>Last Viewed</h3>
        <p>{dayjs(last_viewed, 'DD/MM/YYYY').fromNow()}</p>

        {
          <>
            <TimeLeftAndFirstAid
              current={dayjs().tz('Australia/Melbourne')}
              expiryDate={dayjs(expiry_date, 'DD/MM/YYYY').tz(
                'Australia/Melbourne',
              )}
              first_aid
              {...id}
              key={id}
            />
          </>
        }
      </div>
      <div className="tile_bottom">
        <div className="tile_bottom_wrapper">
          <h1 className="name">
            {first_name} {last_name}
          </h1>
          {activities &&
            activities.map((a, i) => (
              <>
                <div
                  className="featureIcon"
                  key={`activity_${i}_featureicon`}
                />
                <div className="activity" key={`activity_${i}_activity`}>
                  {a}
                </div>
                <br />
              </>
            ))}
          {!activities && <>No Activities Provided</>}
        </div>
      </div>
    </div>
  );
};

export { Tile };
