import React, { useState, useEffect } from 'react';
import { Col, Dropdown, Button, Checkbox, Input } from 'antd';
import { API_ENDPOINT } from '../../config';
import axios from 'axios';
import { FilterOutlined } from '@ant-design/icons';
import { expires } from '../utils';
import { useFilter } from '../../hooks/aiders/';
const { Search } = Input;

function AiderFilter({ applyFilter, filter }) {
  const [firstAidTypes, setFirstAidTypes] = useState('');
  /* eslint-disable */
  const { handleStatusFilter, handleTypesFilter } = useFilter(
    applyFilter,
    filter,
  );

  /* eslint-enable */

  useEffect(() => {
    if (!firstAidTypes) {
      /* eslint-disable */
      axios.get(`${API_ENDPOINT}/firstaidtypes`).then((result) => {
        setFirstAidTypes(result?.data);
      });
      /* eslint-enable */
    }
  }, []);

  const getExpireStatusCheckboxes = (expires) => {
    return expires.map((expire) => {
      return {
        key: expire.key,
        label: (
          <Checkbox
            onChange={() => handleStatusFilter(expire.value)}
            style={{
              textTransform: 'capitalize',
              paddingTop: '5%',
              paddingBottom: '5%',
            }}
          >
            {expire.value}
          </Checkbox>
        ),
      };
    });
  };

  const getFirstAidTypeCheckboxes = (firstAidTypes) => {
    return (
      firstAidTypes &&
      firstAidTypes.map((_type) => {
        return {
          key: _type.id,
          label: (
            <div
              style={{ paddingTop: '5%', paddingBottom: '5%' }}
              key={_type.id}
            >
              <Checkbox
                key={_type.id}
                onChange={() => handleTypesFilter(_type.type)}
              >
                {_type.type}
              </Checkbox>
              <br />
            </div>
          ),
        };
      })
    );
  };

  return (
    <>
      <Col span={1} />
      <Col span={3}>
        <Dropdown
          menu={{
            items: getExpireStatusCheckboxes(expires),
          }}
        >
          <Button
            style={{ width: '100%', borderRadius: 0 }}
            icon={<FilterOutlined />}
          >
            Status
          </Button>
        </Dropdown>
      </Col>
      <Col span={1} />
      <Col span={3}>
        <Dropdown menu={{ items: getFirstAidTypeCheckboxes(firstAidTypes) }}>
          <Button
            style={{ width: '100%', borderRadius: 0 }}
            icon={<FilterOutlined />}
          >
            First Aid Type
          </Button>
        </Dropdown>
      </Col>
      <Col span={11} />
      <Col span={4}>
        <Search placeholder="Search First Aider Here" enterButton />
      </Col>
    </>
  );
}

export { AiderFilter };
