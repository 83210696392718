import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
dayjs.extend(utc);
dayjs.extend(timezone);

/* eslint-disable */
const calculateExpiryTime = (expiryTime) => {
  const currentDate = dayjs().tz('Australia/Melbourne'); // Convert to AUD Time
  const expiryTimeConverted = dayjs(expiryTime, 'DD/MM/YYYY').tz(
    'Australia/Melbourne',
  );
  return dateOutput(currentDate, expiryTimeConverted);
};
/* eslint-enable */

const dateOutput = (currentDate, expiryTimeConverted) => {
  const days = expiryTimeConverted.diff(currentDate, 'day');
  if (days === 0) {
    return expiryTimeConverted.diff(currentDate, 'hour') + ' hours';
  }
  if (days < 30) {
    return days + ' days';
  }

  if (days > 30) {
    const month = expiryTimeConverted.diff(currentDate, 'month');
    if (month <= 12) {
      return month + ' months';
    } else {
      const years = expiryTimeConverted.diff(currentDate, 'year');
      return years + ' years';
    }
  }
};

const getExpiryStatus = (expiryTime) => {
  if (expiryTime.startsWith('-')) {
    return 'expired';
  }
  if (expiryTime.endsWith('days') || expiryTime.endsWith('months')) {
    return 'expiring soon';
  }

  if (expiryTime.endsWith('years')) {
    return 'active';
  }

  return 'Not Found';
};

export { calculateExpiryTime, getExpiryStatus, dateOutput };
