import React, { useState, useEffect } from 'react';
import { expires } from '../utils';
import { Checkbox, Col, Dropdown, Button, Input } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { API_ENDPOINT } from '../../config';
import '../../styles/theme.css';
import axios from 'axios';

const { Search } = Input;

if (process.env.REACT_APP_ENV === 'dev') {
  require('../../mocks/');
}

const GuardsFilter = ({ applyFilter, filter, clearFilter }) => {
  const [activities, setActivities] = useState('');

  const handleStatusFilter = (status) => {
    if (filter?.status && filter.status.includes(status)) {
      applyFilter({ status: filter.status.filter((s) => s !== status) });
    } else {
      applyFilter({
        status: filter?.status ? [...filter.status, status] : [status],
      });
    }
  };

  const handleActivityFilter = (activity) => {
    if (filter?.activities && filter.activities.includes(activity)) {
      applyFilter({
        activities: filter?.activities.filter((a) => a != activity),
      });
    
    } else {
      applyFilter({
        activities: filter?.activities ? [...filter.activities, activity] : [activity]
      });
    }
  };

  const getExpireStatusCheckboxes = (expires) => {
    return expires.map((expire) => {
      return {
        key: expire.key,
        label: (
          <Checkbox
            onChange={() => handleStatusFilter(expire.value)}
            style={{
              textTransform: 'capitalize',
              paddingTop: '5%',
              paddingBottom: '5%',
            }}
          >
            {expire.value}
          </Checkbox>
        ),
      };
    });
  };

  const getActivityCheckboxes = (_activities) => {
    return (
      _activities &&
      _activities.map((_activity) => {
        const { id, activity } = _activity;
        return {
          key: id,
          label: (
            <div
              style={{
                paddingTop: '5%',
                paddingBottom: '5%',
              }}
              key={id}
            >
              <Checkbox
                key={id}
                onChange={() => handleActivityFilter(activity)}
              >
                {activity}
              </Checkbox>
            </div>
          ),
        };
      })
    );
  };

  useEffect(() => {
    if (!activities) {
      axios.get(`${API_ENDPOINT}/activities`).then((result) => {
        setActivities(result.data);
      });
    }
  }, []);

  const firstAidOnly = () => {
    const { firstAid } = filter;
    applyFilter({ firstAid: !firstAid });
  };

  return (
    <>
      <Col span={1} />
      <Col span={3}>
        <Dropdown
          menu={{
            items: getExpireStatusCheckboxes(expires),
          }}
        >
          <Button
            style={{
              width: '100%',
              borderRadius: 0,
              backgroundColor: filter?.status ? 'rgba(22, 119, 255)' : null,
              color: filter?.status ? 'white' : null,
            }}
            icon={<FilterOutlined />}
          >
            Status
          </Button>
        </Dropdown>
      </Col>
      <Col span={1} />
      <Col span={3}>
        <Dropdown menu={{ items: getActivityCheckboxes(activities) }}>
          <Button
            style={{
              width: '100%',
              borderRadius: 0,
              backgroundColor: filter?.activities ? 'rgba(22, 119, 255)' : null,
              color: filter?.activities ? 'white' : null,
            }}
            icon={<FilterOutlined />}
          >
            Activity Types
          </Button>
        </Dropdown>
      </Col>
      <Col span={1} />
      <Col span={4} style={{ paddingTop: '0.3%' }}>
        <Checkbox onChange={firstAidOnly}>First Aid Only?</Checkbox>
      </Col>
      <Col span={2}>
        <div className="clear_filter" onClick={() => clearFilter()}>
          x Clear Filter
        </div>
      </Col>
      <Col span={4} />
      <Col span={4}>
        <Search placeholder="Search Guard Here" enterButton />
      </Col>
    </>
  );
};

export { GuardsFilter };
