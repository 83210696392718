import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
/* eslint-disable */
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
/* eslint-enable */
dayjs.extend(customParseFormat);

const getEighteenYearsAgo = () => {
  const now = dayjs().tz('Australia/Melbourne');
  const formattedDate = now.subtract(18, 'year').format('DD-MM-YYYY');
  return formattedDate;
};

export { getEighteenYearsAgo };
